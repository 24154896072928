<template>
  
  <div>
    <div class="container">
      <b-navbar toggleable="lg" class="ftco_navbar ftco-navbar-light">
      <b-navbar-brand>
        <router-link to="/" class="navbar-brand">
          <img class="logo-topo" src="/img/01.png" alt="Logo">
        </router-link>
      </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="navbar-nav menu-xl ml-auto">
            <b-nav-item exact to="/">Home</b-nav-item>
            <b-nav-item to="/produtos">Produtos</b-nav-item>
            <b-nav-item to="/sobre">Sobre Nós</b-nav-item>
            <b-nav-item to="/blog">Blog</b-nav-item>
            <b-nav-item to="/contato">Contato</b-nav-item>
          </b-navbar-nav>

          <div class="header-right-btn btn-orcamento f-right mt-5 mt-lg-0">
            <b-button 
              v-b-modal.modal-lg 
              variant="primary"
            >
              Orçamento
            </b-button>

            <!-- Modal -->
            <b-modal 
              id="modal-lg" 
              size="lg" 
              hide-footer
            >
              <div class="modal-body px-4">
                <h3 class="color-01 text-center mb-4">Solicite um orçamento</h3>
                <form class="mb-4">
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <input 
                        type="text" 
                        class="form-control" 
                        placeholder="Nome"
                        v-model="nome"
                      >
                    </div>
                    <div class="col-lg-6 mb-3">
                      <input 
                        type="text" 
                        class="form-control" 
                        placeholder="Email"
                        v-model="email"
                      >
                    </div>
                    <div class="col-lg-6 mb-3">
                      <input 
                        type="text" 
                        class="form-control" 
                        placeholder="Telefone"
                        v-model="telefone"
                      >
                    </div>
                    <div class="col-12">
                      <textarea 
                        class="form-control" 
                        id="#" 
                        rows="5"
                        placeholder="Mensagem da solicitação"
                        v-model="conteudo"
                      >
                      </textarea>
                    </div>
                  </div>
                  <input id="arquivo" type="file"  class="my-4">
                  <div>
                    <button 
                      type="button" 
                      class="button button-contactForm boxed-btn" 
                      @click="enviarDadosFormulario"                
                    >
                      Enviar solicitação de orçamento
                    </button>
                  </div>
                </form>                  
              </div>
            </b-modal>
            <!-- Fim Modal -->
          </div>

        </b-collapse>
      </b-navbar>
    </div>
  </div>

</template>


<script>
export default {
  data() {
    return {
      nome: '',
      email: '',
      telefone: '',
      conteudo: '',

      contato: [],
      contatoEmail: '',

      // Objeto Contato
      Contato: {
        NomeEmpresa: "ACE | Acoplamentos e Redutores",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: "",
        Anexo: "",
        NomeAnexo: "",
        TipoAnexo: "",
      }
    }
  },
  created() {

    // Contato
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Info.%20Contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI

      this.contatoEmail = this.contato[0].nome

    });

  },
  methods: {
    enviarDadosFormulario() {

      //validar vazios
      if(this.nome == '' || this.email == '' || this.telefone == '' || this.conteudo == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //Email que irá receber os dados do formulário preenchido (empresa site)
        this.Contato.Destinatario = this.contatoEmail
        // this.Contato.Destinatario = "thiagokdma@gmail.com"

        //Email de quem preencheu formulário e enviou email (usuário/cliente)
        this.Contato.EmailResposta = this.email

        // Dados que aparecerão no corpo do email
        this.Contato.TextoEmail = 
          "Nome:" + this.nome + 
          " <br /> E-mail: " + this.email +
          " <br /> Telefone: " + this.telefone 
          " <br /> Descrição: " + this.conteúdo 
          
        
        // Aplicar (id="arquivo") no input file
        var arquivos = document.getElementById("arquivo").files

        //SE TIVER ANEXO:
        if (arquivos != null && arquivos.length > 0) {
          var arq = "";
          var reader = new FileReader();
          var arquivoAnexo = arquivos[arquivos.length - 1];
          reader.readAsDataURL(arquivoAnexo);
          reader.onload = () => {
            arq = reader.result;
            this.Contato.Anexo = arq.toString().split(",")[1];
            this.Contato.NomeAnexo = arquivoAnexo.name;
            this.Contato.TipoAnexo = arquivoAnexo.type;
            this.Contato.Assunto = "Solicitação de orçamento";
            this.Contato.EmailResposta = this.email
            // console.log(this.Contato);

            this.$http
            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
            .then(
              (response) => {
                if (response.ok) alert("Enviado")
                this.nome= ''
                this.email= ''
                this.telefone= ''
                this.conteudo= ''
                document.getElementById("arquivo").value = "";
                
              },
              (error) => {
                alert(error.bodyText)
                this.nome= ''
                this.email= ''
                this.telefone= ''
                this.conteudo= ''
                document.getElementById("arquivo").value = "";
                
              }
            );
          };
          
        }else {
              
          //SE NAO TIVER ANEXO:
          this.Contato.Assunto = "Solicitação de orçamento";
          this.Contato.EmailResposta = this.email
          console.log(this.Contato);
          this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
          .then(response => {
            if(response.ok)
              alert("Enviado")
              this.nome= ''
              this.email= ''
              this.telefone= ''
              this.conteudo= ''
              
          },error => {
              alert(error.bodyText)
              this.nome= ''
              this.email= ''
              this.telefone= ''
              this.conteudo= ''
            });
          }

        }

      
    }
  }
}
</script>


<style scoped>

  @import '/css/header.css';

</style>