<template>
  
  <footer>
    <div 
      class="footer-wrappper set-img"
      :style="{ backgroundImage: 'url(' + footer_bg + ')' }"
    >
      <div class="footer-area footer-padding">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-3">
              <div class="single-footer-caption">
                <div class="single-footer-caption mb-30">
                  <!-- logo -->
                  <div class="footer-logo mb-25">
                    <router-link to="/">
                      <img src="/img/26.png">
                    </router-link>
                  </div>
                  <div class="footer-tittle">
                    <div class="footer-pera">
                      <p v-html="footerDescricao"></p>
                    </div>
                  </div>
                  <!-- social -->
                  <div class="footer-social">
                    <a v-if="sociaisFacebook!=''" :href="sociaisFacebook" target="_blank"><i class="fa fa-facebook"></i></a>
                    <a v-if="sociaisTwitter!=''" :href="sociaisTwitter" target="_blank"><i class="fab fa-twitter"></i></a>
                    <a v-if="sociaisLinkedin!=''" :href="sociaisLinkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="single-footer-caption mb-20">
                <div class="footer-tittle">
                  <h4>Menu</h4>
                  <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/sobre">Sobre Nós</router-link></li>
                    <li><router-link to="/blog">Blog</router-link></li>
                    <li><router-link to="/contato">Contato</router-link></li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 mt-5 mt-md-0">
              <div class="single-footer-caption mb-20">
                <div class="footer-tittle">
                  <h4>Produtos</h4>
                  <ul>
                    <li 
                      v-for="item in produtoSegmento1" 
                      :key="item.segmentoInstNivel1Id"
                    >
                      <a 
                        href="#" 
                        @click="$redirect_reload('/produtos/' +  item.nomeSegmento.replaceAll(' ','-').replaceAll('/','') + '/' +  item.segmentoInstNivel1Id)"
                      >
                        {{ item.nomeSegmento }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- footer-bottom area -->
      <div class="footer-bottom-area">
        <div class="container">
          <div>
            <div class="row d-flex align-items-center">
              <div class="col-xl-12">
                <div class="footer-copy-right text-center my-5">
                  <a href="https://avancedigital.com.br/" target="_blank">
                    <img class="logo_avance" src="/img/logo_avance.png" alt="">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>


<script>
  export default {
    data() {
      return {

        footer: [],
        footer_bg: '',
        footerDescricao: '',

        sociais:[],
        sociaisFacebook:'',
        sociaisTwitter:'',
        sociaisLinkedin:'',

        produtoSegmento1: [],

      }
    },
    created() {

      // Parceiros
      this.$http
      .get(this.$apiUrl + "/institucional/telas/rodape")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.footer = retornoAPI

        this.footer_bg = this.$apiUrlIndex + this.footer[0].institucionalImg[0].urlImagem
        this.footerDescricao = this.footer[0].descricao1

      });

      // Sociais
      this.$http
      .get(this.$apiUrl + "/institucional/telas/links%20redes%20sociais")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.sociais = retornoAPI

        this.sociaisFacebook = this.sociais[0].campoExtra2
        this.sociaisTwitter = this.sociais[0].linkBtn1
        this.sociaisLinkedin = this.sociais[0].linkBtn2

      });


      // menu
      this.$http
      .get(this.$apiUrl + "/segmentoInstNivel1")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.produtoSegmento1 = retornoAPI.filter((x) => x.visivel)
        this.produtoSegmento1.sort(function (a, b) {
          if (a.nomeSegmento > b.nomeSegmento) { return 1; }        
          if (a.nomeSegmento < b.nomeSegmento) { return -1; }      
          return 0;
        })
      
        if (typeof this.SegmentoNivel1Id != 'undefined'){
          //buscar os itens do segmento2
          this.$http
          .get(this.$apiUrl + "/segmentoInstNivel2/nivel1/" + parseInt(this.SegmentoNivel1Id,10) )
          .then((res) => res.json())
          .then((retornoAPI) => {
            this.DadosSegmentoNivel2 = retornoAPI.filter((x) => x.visivel)
            this.DadosSegmentoNivel2.sort(function (a, b) {
              if (a.nomeSegmento > b.nomeSegmento) { return 1; }        
              if (a.nomeSegmento < b.nomeSegmento) { return -1; }      
              return 0;
            })
            console.log(this.DadosSegmentoNivel2)
          });

        }else{      
          //buscar o primeiro item do segmento2
          this.$http
          .get(this.$apiUrl + "/segmentoInstNivel2/nivel1/" + parseInt(this.produtoSegmento1[0].segmentoInstNivel1Id,10) )
          .then((res) => res.json())
          .then((retornoAPI) => {
            this.DadosSegmentoNivel2 = retornoAPI.filter((x) => x.visivel)
            this.DadosSegmentoNivel2.sort(function (a, b) {
              if (a.nomeSegmento > b.nomeSegmento) { return 1; }        
              if (a.nomeSegmento < b.nomeSegmento) { return -1; }      
              return 0;
            })

          });

        }

      });

    }
  }
</script>