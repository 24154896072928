<template>
  
  <div class="header-area">
    <div class="main-header">
      <div class="header-top d-none d-sm-block">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="d-flex justify-content-between flex-wrap
                align-items-center">
                <div class="header-info-left">
                  <ul>
                    <li><a :href="linkFone1" style="color: #0146a7 !important">{{ topbarFone }}</a></li>
                    <li><a :href="linkFone2" style="color: #0146a7 !important">{{ topbarAtendimento }}</a></li>
                  </ul>
                </div>
                <!-- <div class="header-info-right d-none d-md-block">
                  <ul class="header-social">
                    <li><a :href="sociaisFacebook" target="_blank"><i class="fab fa-facebook"></i></a></li>
                    <li><a :href="sociaisTwitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a :href="sociaisLinkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {

      topbar: [],
      topbarFone: '',
      topbarAtendimento: '',
      linkFone1:'',
      linkFone2:'',

      sociais:[],
      sociaisFacebook:'',
      sociaisTwitter:'',
      sociaisLinkedin:'',

    }
  },
  created() {

    // Topbar
    this.$http
    .get(this.$apiUrl + "/institucional/telas/cabeçalho")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topbar = retornoAPI

      this.topbarFone = this.topbar[0].nome
      this.topbarAtendimento = this.topbar[0].campoExtra1
      this.linkFone1 = this.topbar[0].linkBtn1
      this.linkFone2 = this.topbar[0].linkBtn2

    });


    // Sociais
    this.$http
    .get(this.$apiUrl + "/institucional/telas/links%20redes%20sociais")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sociais = retornoAPI

      this.sociaisFacebook = this.sociais[0].campoExtra2
      this.sociaisTwitter = this.sociais[0].linkBtn1
      this.sociaisLinkedin = this.sociais[0].linkBtn2

    });
  }
}
</script>