<template>

  <div>

    <!-- Carousel -->
    <div class="hidden-sm">
      <b-carousel
        id="carousel-fade"
        fade
        indicators
        :interval="5000"
        img-width="1024"
        img-height="400"
      >
        <b-carousel-slide
        v-for="slide in carousel" :key="slide.id"
        :img-src="$apiUrlIndex + slide.institucionalImg[0].urlImagem">
          <h1 class="carousel-title">{{ slide.nome }}</h1>
          <p 
            class="carousel-descricao"
            v-html="slide.descricao1"></p>
          <div class="mt-5">
            <a 
              :href="slide.linkBtn1" 
              class="btn-rounded btn-carousel pointer"
            >
              {{ slide.textoBtn1 }}
            </a>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>

    <!-- Card Serviços -->
    <section 
      class="categories-area"
      style="background-color: #c8ebeb;"
    >
      <div class="container">
        <div class="row">
          <div 
            class="col-xl-2 col-lg-4 col-md-4 col-6"
            v-for="card in cardProdutos"
            :key="card.id"
          >
            <div class="single-cat text-center mb-30">
              <div class="cat-icon">
                <a :href="card.linkBtn1">
                  <img :src="$apiUrlIndex + card.institucionalImg[0].urlImagem">
                </a>
              </div>
              <div class="cat-cap">
                 <a :href="card.linkBtn1">
                   <h6>{{ card.nome }}</h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Sobre -->
    <div 
      class="about-area"
      style="background-color: #0146a7;"
    >
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-10">
            <div class="about-cap-Left">
              <div class="section-tittle section-tittle2 mb-50">
                <h2>{{ sobreHomeTitulo }}</h2>
                <p v-html="sobreHomeDescricao"></p>
              </div>
              <img :src="sobreHomeImg">
            </div>
          </div>
          <div class="col-xl-5 col-lg-5 offset-lg-1 col-md-10">
            <div 
              class="right-caption"
              v-for="item in mvv"
              :key="item.id"
            >
              <div class="small-tittle mb-50">
                <h4>{{ item.nome }}</h4>
                <p v-html="item.descricao1"></p>
              </div>
              
              
            </div>
            <div class="mb-4">
              <router-link 
                to="/sobre" 
                class="btn_01 about-btn"
              >
                Saiba mais sobre nós
              </router-link>
              <!-- <img 
                src="/img/05.png"
                class="mt-5"
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Produtos -->
    <div class="location-house fix spad-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 col-sm-11">
            <!-- Section Tittle -->
            <div class="section-tittle text-center mb-60">
              <h2 class="color-01">Nossos Produtos</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div 
            class="col-md-6 col-lg-4 mb-4"
            v-for="destaque in produtosEmDestaque"
            :key="destaque.segmentoInstNivel2Id"
          >
            <div class="single-project flex-center">
              <img :src="$apiUrlIndex + destaque.institucionalImg[0].urlImagem">
              <div class="project-contents">
                <h3>{{ destaque.segmentoInstNivel1.nomeSegmento }}</h3>
                <!-- <p v-html="destaque.resumo1" class="mb-3"></p> -->
                <a 
                  href="#" 
                  @click="$redirect_reload('/produtoDetalhe/' +  destaque.segmentoInstNivel1.nomeSegmento.replaceAll(' ','-').replaceAll('/','') + '/' +  destaque.segmentoInstNivel2.segmentoInstNivel2Id)" class="border-btn"
                >
                  Mais detalhes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Depoimentos + Form -->
    <!-- <div class="visit-tailor-area fix">
      
      <div 
        class="left-colum"
        :style="{ backgroundImage: 'url(' + depoImg_img + ')' }"
      >
      </div>
      
      <div class="mid-colum">
        <section class="testimonial-area">
          <div class="h1-testimonial-active">
            <div class="single-testimonial position-relative">
              <div class="testimonial-caption">
                <img src="/img/left-quotes-sign.svg" alt=""
                  class="quotes-sign">
                <h2 class="color-01">Seu depoimento</h2>
                <p v-html="depoimentosDescricao"></p>
              </div>

              <div class="testimonial-founder d-flex align-items-center">
                <div class="founder-img">
                  <img :src="depoimentosImg" alt="">
                </div>
                <div class="founder-text">
                  <span>{{ depoimentosNome }}</span>
                  <p>{{ depoimentosProfissao }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      
      <div class="right-colum">
        <div class="form-wrapper">
          <div class="form-tittle mb-30">
            <h2 class="color-01">Orçamento</h2>
          </div>
          <form id="three-form" action="#" method="POST">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-box mb-15">
                  <input 
                    type="text" 
                    name="name" 
                    placeholder="Seu Nome"
                    class="inputName"
                    v-model="nome"
                  >
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-box mb-15">
                  <input 
                    type="text" 
                    name="email" 
                    placeholder="Seu Email"
                    v-model="email"
                  >
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-box mb-15">
                  <textarea 
                    name="message" 
                    id="message" 
                    placeholder="Mensagem"
                    v-model="conteudo"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
        
        <div class="page-links">
          <button 
            class="view2" 
            type="button"
            @click="enviarDadosFormulario"
          >
            Enviar Orçamento
          </button>
        </div>
      </div>
    </div> -->

    <!-- Parceiros -->
    <Parceiros />

  </div>
</template>

<script>

import Parceiros from '../shared/Parceiros.vue'

export default {
  components: {
    Parceiros
  },
  data() {
    return {
      carousel: [],
     
      cardProdutos: [],

      sobreHome: [],
      sobreHomeTitulo: '',
      sobreHomeDescricao: '',
      sobreHomeImg: '',

      produtosEmDestaque: [],

      mvv: [],

      depoImg: [],
      depoImg_img: '',

      depoimentos: [],
      depoimentosNome: '',
      depoimentosProfissao: '',
      depoimentosDescricao: '',
      depoimentosImg: '',

      nome: '',
      email: '',
      conteudo: '',

      contato: [],
      contatoEmail: '',


      // Objeto Contato      
      Contato: {
        NomeEmpresa: "ACE | Acoplamentos e Redutores",
        Assunto: "Solicitação de orçamento",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    // Slider
    this.$http
    .get(this.$apiUrl + "/institucional/telas/slider")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.carousel = retornoAPI.filter((x) => x.visivel)

      this.carousel.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      })

    });


    // Card Serviços (Ícones)
    this.$http
    .get(this.$apiUrl + "/institucional/telas/icones")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.cardProdutos = retornoAPI.filter((x) => x.visivel)

      this.cardProdutos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      })

    });


    // Sobre Home
    this.$http
    .get(this.$apiUrl + "/institucional/telas/sobre%20home")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobreHome = retornoAPI

      this.sobreHomeTitulo = this.sobreHome[0].nome
      this.sobreHomeDescricao = this.sobreHome[0].descricao1
      this.sobreHomeImg = this.$apiUrlIndex + this.sobreHome[0].institucionalImg[0].urlImagem

    });


    // Sobre Home Itens
    this.$http
    .get(this.$apiUrl + "/institucional/telas/sobre%20home%20itens")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.mvv = retornoAPI.filter((x) => x.visivel)

      this.mvv.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      })

    });


    // Depoimentos imagem
    this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentos%20imagem")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.depoImg = retornoAPI

      this.depoImg_img = this.$apiUrlIndex + this.depoImg[0].institucionalImg[0].urlImagem

    });


    // Depoimentos
    this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.depoimentos = retornoAPI.filter((x) => x.visivel)

      this.depoimentos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      }).reverse()

      this.depoimentosNome = this.depoimentos[0].nome 
      this.depoimentosProfissao = this.depoimentos[0].campoExtra1 
      this.depoimentosDescricao = this.depoimentos[0].descricao1 

      this.depoimentosImg = this.$apiUrlIndex + this.depoimentos[0].institucionalImg[0].urlImagem

    });


    // Produtos
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Cad.%20Produtos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.produtosEmDestaque = retornoAPI.filter((x) => x.visivel && x.destaque)

      this.produtosEmDestaque.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      })

    });


    // Contato
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Info.%20Contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI

      this.contatoEmail = this.contato[0].nome

    });
    
  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail
      // this.Contato.Destinatario = "thiagokdma@gmail.com"

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Email: " + this.email +
        " <br /> Descrição: " + this.conteudo

      //validar vazios
      if(this.nome == '' || this.email == '' || this.conteudo == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            alert("Enviado com sucesso!")
            this.nome = ''
            this.email = ''
            this.conteudo = ''
            this.Contato.Assunto=""
        },error => {
          alert(error.bodyText)
          this.nome = ''
          this.email = ''
          this.conteudo = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }

  
  
}

</script>

<style scoped>

  @import '/css/carousel.css;'

</style>

