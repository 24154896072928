<template>

  <div>

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />


    <section class="blog_area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mb-5 mb-lg-0">
            <div class="row">
              <div 
                class="col-md-6"
                v-for="noticia in blog"
                :key="noticia.id"
              >
                <div class="blog_left_sidebar">
                  <article class="blog_item">
                    <div class="blog_item_img">
                      <router-link :to="/blogDetalhe/ + noticia.institucionalId">
                        <img 
                          class="card-img rounded-0"
                          :src="$apiUrlIndex + noticia.institucionalImg[0].urlImagem"
                        >
                      </router-link>
                      <!-- <a href="#" class="blog_item_date">
                        <h3>15</h3>
                        <p>Jan</p>
                      </a> -->
                    </div>
                    <div class="blog_details">
                      <span class="d-block">{{ currentDateTime(noticia.data1) }}</span>
                      <router-link 
                        :to="/blogDetalhe/ + noticia.institucionalId"
                        class="d-inline-block"
                      >
                        <h2 class="blog-head"> {{ noticia.nome }}</h2> 
                      </router-link>
                      <p v-html="noticia.resumo1"></p>
                    </div>
                  </article>
                  
                </div>
              </div>
            </div>
            <!-- Paginador -->
            <!-- <div class="row">
              <div class="col text-center">
                <nav class="blog-pagination justify-content-center d-flex">
                  <ul class="pagination">
                    <li class="page-item">
                      <a href="#" class="page-link" aria-label="Previous">
                        <i class="ti-angle-left"></i>
                      </a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link">1</a>
                    </li>
                    <li class="page-item active">
                      <a href="#" class="page-link">2</a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link" aria-label="Next">
                        <i class="ti-angle-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div> -->
          </div>

          <div class="col-lg-4">
            <div class="blog_right_sidebar">
              <aside class="single_sidebar_widget popular_post_widget">
                <h3 class="widget_title">Notícias Recentes</h3>
                <div 
                  class="media post_item"
                  v-for="noticia in blog"
                  :key="noticia.id"
                >
                  <router-link :to="/blogDetalhe/ + noticia.institucionalId">
                    <img :src="$apiUrlIndex + noticia.institucionalImg[0].urlImagem">
                  </router-link>
                  <div class="media-body">
                    <router-link :to="/blogDetalhe/ + noticia.institucionalId">
                      <h3 class="side_blog-title">{{ noticia.nome }}</h3>
                    </router-link>
                    <p>{{ currentDateTime(noticia.data1) }}</p>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Parceiros -->
    <Parceiros />

  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'
import Parceiros from '../shared/Parceiros.vue'
import moment from 'moment'

export default {
  components: {
    TopoInternas,
    Parceiros
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      blog: [],
      blogTitulo: '',
      blogResumo: '',
      blogDescricao: '',

    }
  },
  created() {

    // Blog Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20blog")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    // Blog
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Cad.%20Notícias")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.blog = retornoAPI.filter((x) => x.visivel)

    });

  },
  methods: {
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }
  }
  
}

</script>



