<template>

  <div>

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />


    <!-- Detalhe -->
    <section class="spad-50">
      <div class="container">
        <!-- Breadcrumb -->
        <div class="row">
          <div class="col mb-4">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:window.history.go(-1)">{{ produtoNomeSegmento1 }}</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ produtoNomeSegmento2 }}</li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- Gallery -->
        <div class="row">
          <div class="col-lg-6">
            <lingallery 
              :addons="{ enableLargeView: true }"
              :iid.sync="currentId" 
              width="100%"
              :height="500" 
              :items="produtoImg" 
              
            />

            <!-- <lingallery :iid.sync="currentId" width="100%" :height="400" :items="[
              {id:'someid1', src: '/img/10.png', thumbnail: '/img/10.png'},
              
              {id:'someid2', src: 'https://picsum.photos/600/400/?image=10', thumbnail: 'https://picsum.photos/64/64/?image=10'},
              {id:'someid3', src: 'https://picsum.photos/400/600/?image=20', thumbnail: 'https://picsum.photos/64/64/?image=20'}
            ]"/> -->
          </div>
          
          <div class="col-lg-6 mt-5 mt-lg-0">
            <h4>{{ produtoNomeSegmento2 }}</h4>
            <p>{{ produtoRef }}</p>
            <p v-html="produtoResumo"></p>

            <div class="text-center spad-50">
              <b-button 
                v-b-modal.modal-md 
                variant="primary"
                class="px-5 py-4"
              >
                Fazer orçamento deste produto
              </b-button>

              <!-- Modal -->
              <b-modal 
                id="modal-md" 
                size="lg" 
                hide-footer
              >
                <div class="modal-body px-4">
                  <h3 class="color-01 text-center mb-4">Solicite um orçamento</h3>
                  <form class="mb-4">
                    <div class="row">
                      <div class="col-lg-6 mb-3">
                        <input 
                          type="text" 
                          class="form-control" 
                          placeholder="Nome"
                          v-model="nome"
                        >
                      </div>
                      <div class="col-lg-6 mb-3">
                        <input 
                          type="text" 
                          class="form-control" 
                          placeholder="Email"
                          v-model="email"
                        >
                      </div>
                      <div class="col-lg-6 mb-3">
                        <input 
                          type="text" 
                          class="form-control" 
                          placeholder="Telefone"
                          v-model="telefone"
                        >
                      </div>
                      <div class="col-lg-6 mb-3">
                        <input 
                          type="text" 
                          class="form-control" 
                          disabled
                          :placeholder="'Produto: ' + produtoNomeSegmento2"
                        >                         
                      </div>
                      <div class="col-12">
                        <textarea 
                          class="form-control" 
                          id="#" 
                          rows="5"
                          placeholder="Mensagem da solicitação"
                          v-model="conteudo"
                        >
                        </textarea>
                      </div>
                    </div>
                    <input id="arquivo" type="file" class="my-4">
                    <div>
                      <button 
                        type="button" 
                        class="button button-contactForm boxed-btn" 
                        @click="enviarDadosFormulario"                
                      >
                        Enviar solicitação de orçamento
                      </button>
                    </div>
                  </form>                  
                </div>
              </b-modal>
              <!-- Fim Modal -->

            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Tab -->
    <div class="">
      <div class="container">
        <div class="row">
          <div class="col">
            <b-tabs 
              content-class="border-top"      
            >
              <!-- 1 -->
              <b-tab 
                title="Características" 
                active
              >
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-lg-10 col-xl-9">
                      <p v-html="produtoCaract"></p>
                    </div>
                  </div>
                </div>
              </b-tab>

              <!-- 2 -->
              <!-- <b-tab 
                title="Componentes" 
              >
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-lg-10 col-xl-9">
                      <p v-html="produtoComponentes"></p>
                    </div>
                  </div>
                </div>
              </b-tab> -->

            </b-tabs>
          </div>
        </div>
      </div>
      
    </div>

    <!-- Tab v-for -->
    <!-- <div class="section-mvv">
      
      <b-tabs 
        content-class="mt-3" 
        align="center"     
      >
        <b-tab 
          v-for="item in mvv"
          :key="item.id"
          class="" 
          :title="item.nome" 
          active
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10 col-xl-9 text-center">
                <p v-html="item.descricao1"></p>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div> -->


    <!-- Parceiros -->
    <Parceiros />

  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'
import Parceiros from '../shared/Parceiros.vue'

export default {
  components: {
    TopoInternas,
    Parceiros
  },
  data() {
    return {
      
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      produtos: [],
      SegmentoNivel2Id: this.$route.params.idSegmento2,
      produtoNomeSegmento1: '',
      produtoNomeSegmento2: '',
      produtoRef: '',
      produtoResumo: '',
      // produtoComponentes: '',
      produtoCaract: '',
      produtoGaleriaImg: [],

      nome: '',
      email: '',
      telefone: '',
      conteudo: '',

      contato: [],
      contatoEmail: '',

      produtoImg: [],
       currentId: null,

      // Objeto Contato
      Contato: {
        NomeEmpresa: "ACE | Acoplamentos e Redutores",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: "",
        Anexo: "",
        NomeAnexo: "",
        TipoAnexo: "",
      }

    }
  },
  created() {

    // Produtos Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20produtos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    // Produtos
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Cad.%20Produtos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.produtos = retornoAPI.filter((x) => x.visivel && x.segmentoInstNivel2 != null && x.segmentoInstNivel2.segmentoInstNivel2Id == parseInt(this.SegmentoNivel2Id,10))
  
      this.produtos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      })

      this.produtoNomeSegmento1 = this.produtos[0].segmentoInstNivel1.nomeSegmento
      this.produtoNomeSegmento2 = this.produtos[0].segmentoInstNivel2.nomeSegmento
      this.produtoRef = this.produtos[0].referencia
      this.produtoResumo = this.produtos[0].resumo1
      this.produtoCaract = this.produtos[0].descricao1
      // this.produtoComponentes = this.produtos[0].descricao2

      this.produtos[0].institucionalImg.sort(function (a, b) {
        return a.ordem - b.ordem;
      }).reverse().forEach(element => {
        this.produtoImg.push({ 
          src: this.$apiUrlIndex + element.urlImagem, thumbnail: this.$apiUrlIndex + element.urlImagem 
        })

      });
      

    }); 
    
    
    // Contato
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Info.%20Contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI

      this.contatoEmail = this.contato[0].campoExtra1

    });


  },
  methods: {
    enviarDadosFormulario() {
      
      //validar vazios
      if(this.nome == '' || this.email == '' || this.telefone == '' || this.conteudo == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //Email que irá receber os dados do formulário preenchido (empresa site)
        this.Contato.Destinatario = this.contatoEmail
        // this.Contato.Destinatario = "thiagokdma@gmail.com"

        //Email de quem preencheu formulário e enviou email (usuário/cliente)
        this.Contato.EmailResposta = this.email

        // Dados que aparecerão no corpo do email
        this.Contato.TextoEmail = 
          "Nome:" + this.nome + 
          " <br /> E-mail: " + this.email +
          " <br /> Telefone: " + this.telefone 
          " <br /> Descrição: " + this.conteúdo 
          
        
        // Aplicar (id="arquivo") no input file
        var arquivos = document.getElementById("arquivo").files

        //SE TIVER ANEXO:
        if (arquivos != null && arquivos.length > 0) {
          var arq = "";
          var reader = new FileReader();
          var arquivoAnexo = arquivos[arquivos.length - 1];
          reader.readAsDataURL(arquivoAnexo);
          reader.onload = () => {
            arq = reader.result;
            this.Contato.Anexo = arq.toString().split(",")[1];
            this.Contato.NomeAnexo = arquivoAnexo.name;
            this.Contato.TipoAnexo = arquivoAnexo.type;
            this.Contato.Assunto = "Solicitação de orçamento";
            this.Contato.EmailResposta = this.email
            console.log(this.Contato);

            this.$http
            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
            .then(
              (response) => {
                if (response.ok)
                this.nome= ''
                this.email= ''
                this.telefone= ''
                this.conteudo= ''
                document.getElementById("arquivo").value = "";
                this.$redirect_reload('/contatoenviado/');
                
              },
              (error) => {
                this.$mensagem_normal(error.bodyText)
                this.nome= ''
                this.email= ''
                this.telefone= ''
                this.conteudo= ''
                document.getElementById("arquivo").value = "";
                
              }
            );
          };
        }
        else
        {
          //SE NAO TIVER ANEXO:
          this.Contato.Assunto = "Solicitação de orçamento";
          this.Contato.EmailResposta = this.email
          console.log(this.Contato);
          this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
          .then(response => {
            if(response.ok)
              
              this.nome= ''
              this.email= ''
              this.telefone= ''
              this.conteudo= ''
               this.$redirect_reload('/ContatoEnviado/');
              
          },error => {
              this.$mensagem_normal(error.bodyText)
              this.nome= ''
              this.email= ''
              this.telefone= ''
              this.conteudo= ''
          });
        }

      }
      
    }

  }
  
}

</script>

<style scoped>

  @import '/css/modal.css';

</style>

