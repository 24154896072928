<template>
  
  <!-- Histórico (Timeline) -->
  <div class="historico spad-100 bg-white">
    <div class="container">
      <div class="row mb-5">
        <div class="col text-center">
          <div class="text-center">
            <h4 class="title-secondary">Histórico</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10 offset-2 col-md-6 offset-md-6">
          <ul class="timeline">
            <li v-for="historia in historico" :key="historia.id">
              <h2 class="time-data" v-html="historia.nome"></h2>
              <p class="time-text" v-html="historia.descricao1"></p>
            </li>  
            <!-- <li>
              <h2 class="time-data">2022</h2>
              <p class="time-text">Lorem ipsum dolor sit amet consectetur elit.</p>
            </li>             -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Timeline -->
    <!-- <section class="section_pad-100">
      <div class="container">
        <div class="row mb-5">
          <div class="col text-center heading-section">          
            <div class="text-center">
              <h2 class="title-h2 text-blue">Histórico</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 offset-2 col-md-6 offset-md-6">
            <ul class="timeline">
              <li 
                v-for="time in timeline"
                :key="time.id"
              >
                <h2 class="time-data">{{ time.nome }}</h2>
                <p 
                  class="time-text"
                  v-html="time.descricao1"></p>
              </li>             
            </ul>
          </div>
        </div>
      </div>
    </section>  -->

</template>

<script>
export default {
  data() {
    return {
      historico:  []
    }
  },
  created() {

    // Timeline
    this.$http
    .get(this.$apiUrl + "/institucional/telas/historico")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.historico = retornoAPI.filter((x) => x.visivel)

      this.historico.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      })

    });

  }
}
</script>

<style scoped>

  @import '/css/timeline.css';

</style>