<template>

  <div>

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />


    <!-- Produtos -->
    <section class="produtos spad-100">
      <div class="container">
        <div class="row">
          <div class="col-11 col-md-6 col-lg-3 mb-5">
            <ul class="side-produtos">
              <li 
                v-for="item in produtoSegmento1" 
                :key="item.segmentoInstNivel1Id"
              >
                <a 
                  href="#" 
                  @click="$redirect_reload('/produtos/' +  item.nomeSegmento.replaceAll(' ','-').replaceAll('/','') + '/' +  item.segmentoInstNivel1Id)"
                >
                  {{ item.nomeSegmento }}
                  <i class="fa fa-chevron-right"></i>
                </a>
              </li>

            </ul>
          </div>

          <!-- Main -->
          <div id="DescTexto" class="col-md-12 col-lg-8 offset-lg-1">
            <div class="row">
              <div 
                class="col-md-6 location-house fix mb-4" 
                v-for="dsegmento2 in DadosSegmentoNivel2" 
                :key="dsegmento2.segmentoInstNivel2Id"
              >
                <div class="single-project flex-center">
                  <img 
                    v-if="dsegmento2.imagens!=''" 
                    :src="$apiUrlIndex + dsegmento2.imagens[0].urlImagem"
                  >
                  <img v-else src="/img/indisponivel.jpg">

                  <div class="project-contents">
                    <h3>{{ dsegmento2.nomeSegmento }}</h3>
                    <a 
                      href="#" 
                      @click="$redirect_reload('/produtoDetalhe/' +  dsegmento2.nomeSegmento.replaceAll(' ','-').replaceAll('/','') + '/' +  dsegmento2.segmentoInstNivel2Id)" class="border-btn"
                    >
                      Mais detalhes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Parceiros -->
    <Parceiros />

  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'
import Parceiros from '../shared/Parceiros.vue'
import VueScrollTo from 'vue-scrollto';

export default {
  components: {
    TopoInternas,
    Parceiros
  },
  data() {
    return {
      SegmentoNivel1Id: this.$route.params.id,
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      produtoSegmento1: [],
      DadosSegmentoNivel2:[],

    }
  },
  created() {

 // Produtos Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20produtos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    // menu
    this.$http
    .get(this.$apiUrl + "/segmentoInstNivel1")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.produtoSegmento1 = retornoAPI.filter((x) => x.visivel)
      this.produtoSegmento1.sort(function (a, b) {
        if (a.nomeSegmento > b.nomeSegmento) { return 1; }        
        if (a.nomeSegmento < b.nomeSegmento) { return -1; }      
        return 0;
      })
    
      if (typeof this.SegmentoNivel1Id != 'undefined'){
        //buscar os itens do segmento2
        this.$http
        .get(this.$apiUrl + "/segmentoInstNivel2/nivel1/" + parseInt(this.SegmentoNivel1Id,10) )
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.DadosSegmentoNivel2 = retornoAPI.filter((x) => x.visivel)
          this.DadosSegmentoNivel2.sort(function (a, b) {
            if (a.nomeSegmento > b.nomeSegmento) { return 1; }        
            if (a.nomeSegmento < b.nomeSegmento) { return -1; }      
            return 0;
          })
          console.log(this.DadosSegmentoNivel2)
          if (window.innerWidth <= 768) {

            // Opções para a rolagem suave
            const options = {
              container: 'body',
              duration: 800,
              easing: 'ease',
            };

            // Constrói o seletor para o elemento de destino
            const targetSelector = "#DescTexto"; 

            // Rola suavemente até o elemento de destino
            VueScrollTo.scrollTo(targetSelector, options);
          }

        });

      }else{      
        //buscar o primeiro item do segmento2
        this.$http
        .get(this.$apiUrl + "/segmentoInstNivel2/nivel1/" + parseInt(this.produtoSegmento1[0].segmentoInstNivel1Id,10) )
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.DadosSegmentoNivel2 = retornoAPI.filter((x) => x.visivel)
          this.DadosSegmentoNivel2.sort(function (a, b) {
            if (a.nomeSegmento > b.nomeSegmento) { return 1; }        
            if (a.nomeSegmento < b.nomeSegmento) { return -1; }      
            return 0;
          })
          console.log(this.DadosSegmentoNivel2)
         
        });

      }

    });
     
   
  }  
}

</script>



