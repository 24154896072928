<template>

  <div>

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />


    <section class="blog_area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mb-5 mb-lg-0">
            <h2>{{ blogTitulo }}</h2>
            <img 
              class="card-img rounded-0 my-5"
              :src="blogImg"
            >
            <p v-html="blogDescricao"></p>
          </div>

          <!-- Side -->
          <div class="col-lg-4">
            <div class="blog_right_sidebar">
              <aside class="single_sidebar_widget popular_post_widget">
                <div 
                  class="media post_item"
                  v-for="noticia in blog"
                  :key="noticia.id"
                >
                  <a 
                    href="#"
                    @click="carregaNoticia(noticia.institucionalId)"
                  >
                  <img :src="$apiUrlIndex + noticia.institucionalImg[0].urlImagem">
                  </a>
                  <div class="media-body">
                    <a 
                      href="#"
                      @click="carregaNoticia(noticia.institucionalId)"
                    >
                      <h3 class="side_blog-title">{{ noticia.nome }}</h3>
                    </a>
                    <p>{{ currentDateTime(noticia.data1) }}</p>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Parceiros -->
    <Parceiros />

  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'
import Parceiros from '../shared/Parceiros.vue'
import moment from 'moment'

export default {
  components: {
    TopoInternas,
    Parceiros
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      blog: [],

      noticia: {},
      blogImg: '',
      blogTitulo: '',
      blogDescricao: '',

    }
  },
  created() {

    // Blog Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20blog")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    // Blog
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Cad.%20Notícias")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.blog = retornoAPI.filter((x) => x.visivel)

    });

    this.carregaNoticia(this.$route.params.idNoticia)

  },
  methods: {

    carregaNoticia(idNoticia) {    
      this.$http
      .get(this.$apiUrl + "/institucional/" + idNoticia)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.noticia = retornoAPI

        this.blogTitulo = this.noticia.nome
        this.blogDescricao = this.noticia.descricao1
        this.blogImg = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem

        if (this.noticia.institucionalImg[0] != null) {          
          this.blogImg = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem;
        }
        else {          
          this.blogImg = '';
        }
          
      });
    },
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }
  }
  
}

</script>



