<template>
  
  <!-- Tab -->
  <div class="section-mvv">
     
    <b-tabs 
      content-class="mt-3" 
      align="center"     
    >
      <b-tab 
        v-for="item in mvv"
        :key="item.id"
        class="" 
        :title="item.nome" 
        active
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-xl-9 text-center">
              <p v-html="item.descricao1"></p>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>

</template>

<script>
export default {
  data() {
    return {
      mvv: []
    }
  },
  created() {

    
    // MVV
    this.$http
    .get(this.$apiUrl + "/institucional/telas/missao,%20visao%20e%20valores")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.mvv = retornoAPI.filter((x) => x.visivel)

      this.mvv.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      })

    });

  }
}
</script>

<style scoped>

  @import '/css/tabs.css';

</style>