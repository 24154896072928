<template>
  
  <div 
    class="slider-area set-img"
    :style="{ backgroundImage: 'url(' + topo_bg + ')' }"
  >
      <div class="slider-height2 slider-bg5 hero-overly02 d-flex
        align-items-center">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-6">
              <div class="hero-caption hero-caption2 text-center">
                <h2>{{ topoTitulo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  props: ['topo_bg', 'topoTitulo']
}
</script>