<template>
  <div id="app">

    <div v-html="codigo_SEO"></div>

    <Topbar />
    <Header />

    <transition name="fadeIn">
      <router-view></router-view>
    </transition>

    <Footer />

  </div>
</template>

<script>

  import Topbar from './components/site/shared/Topbar.vue'
  import Header from './components/site/shared/Header.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
    name: 'App',
    components: {
      Topbar,
      Header,
      Footer
    },
    data() {
      return {
        // SEO
        seobody: [],
        codigo_SEO: '',

        // Head
        seoHead:[],
        codigoHead: '',

        // Analitics
        seoAnalitics:[],
        codigoAnalitics: '',

        // Home
        metaHome: [],
        codigoHome: ''
        
      }
    },
    created() {
      //----------------- seobody
      /*$route.fullPath*/
      this.$http
      .get(this.$apiUrl + "/seobody/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seobody = retornoAPI
        this.codigo_SEO = this.seobody[0].codigo
      });

      //----------------- seohead
      this.$http
      .get(this.$apiUrl + "/seohead/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seoHead = retornoAPI;
        this.codigoHead = this.seoHead[0].codigo;
      });

      //----------------- analitycs
      this.$http
      .get(this.$apiUrl + "/seoanalitycs/")
      .then((res) => res.json())
      .then((idi) => {
        this.seoAnalitics = idi;
        this.codigoAnalitics = this.seoAnalitics[0].codigo;
      });

      //chama o get da api this.$apiUrl
      this.$http
      .get(this.$apiUrl + "/Seometahome/")
      .then((res) => res.json())
      .then((idi) => {
        this.metaHome = idi;
        this.codigoHome = this.metaHome[0].codigo;
        document.head.innerHTML += this.codigoHead.trim('"') + this.codigoAnalitics.trim('"') + this.codigoHome.trim('"');
      });
    }
  }
</script>

<style>

  /* css tmp */
  @import '/css/slicknav.css';
  @import '/css/slick.css';
  @import '/css/style.css';
  @import '/css/default.css';
  @import '/css/carousel.css';
  @import '/css/depoimentos.css';
  @import '/css/avanceDigital.css';

  /* ---------  */
  .ql-align-justify {
  text-align: justify !important;
  }
  .ql-align-right{
  text-align: right !important;
  }

  .ql-align-center{
  text-align: center !important;
  }
   .ql-size-large{
    font-size: 25px;
  }

  .ql-size-huge{
    font-size: 45px;
  }

  .ql-size-small{
     font-size: 12px;
  }


  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

</style>
