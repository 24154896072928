<template>

  <div>

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />

    <!-- Sobre -->
    <div class="services-details spad-50">
      <div class="container">
         <div class="row">
          <div class="col">
            <div class="section-tittle text-center mb-60">
              <h2 class="color-01">{{ Titulo }}</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-12 mb-5 mb-lg-0">
            <div class="section-tittle">
              <p v-html="Texto"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'
//import Tabs from '../shared/Tabs.vue'
//import Timeline from '../shared/Timeline.vue'
////import Parceiros from '../shared/Parceiros.vue'

export default {
  components: {
    TopoInternas,
/*     Tabs,
    Timeline,
    Parceiros */
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      sobre: [],
      Titulo: '',
      Texto: '',
    }
  },
  created() {

    // Sobre Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    // Sobre
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Contato%20Enviado%20Texto")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobre = retornoAPI

      this.Titulo = this.sobre[0].nome
       this.Texto = this.sobre[0].resumo1

    });


  }
  
}

</script>

<style scoped>

  /* @import '/css/carousel.css'; */

</style>

