<template>
  
  <section class="parceiros smar-100">
    <div class="container bordered">
      <div class="row">
        <div 
          class="col-6 col-md-4 col-lg-2 text-center mb-2"
          v-for="parceiro in parceiros"
          :key="parceiro.id"
        >
          <img :src="$apiUrlIndex + parceiro.institucionalImg[0].urlImagem">
        </div>
      </div>
    </div>
  </section>

</template>

<script>
  export default {
    data() {
      return {

        parceiros: []

      }
    },
    created() {

      // Parceiros
      this.$http
      .get(this.$apiUrl + "/institucional/telas/parceiros")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.parceiros = retornoAPI.filter((x) => x.visivel)

      this.parceiros.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }        
        if (a.ordem < b.ordem) { return -1; }      
        return 0;
      })

      

      });

    }
  }
</script>

<style scoped>

  .bordered {
    border: 4px solid #ccc;
  }

</style>