<template>

  <div>

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />

    <section class="contato spad-100">
      <div class="container">
        <div class="row">

          <div class="col-lg-3">
            <h5 class="mb-4">Entre em contato</h5>
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-home"></i></span>
              <div class="media-body">
                <p v-html="contatoEnd"></p>
              </div>
            </div>
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-tablet"></i></span>
              <div class="media-body">
                <p v-html="contatoTel"></p>
              </div>
            </div>
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-email"></i></span>
              <div class="media-body">
                <h3 class="color-01">{{ contatoEmail }}</h3>
              </div>
            </div>
          </div>
          <div class="col-lg-8 offset-lg-1">
            <form class="form-contact contact_form mt-5 mt-lg-0"
              action="contact_process.php" method="post" id="contactForm"
              novalidate="novalidate">
              <div class="row">
                
                <div class="col-sm-6">
                  <div class="form-group">
                    <input 
                      class="form-control valid" 
                      name="name" 
                      id="name"
                      type="text" onfocus="this.placeholder= ''"
                      onblur="this.placeholder= 'Nome'"
                      placeholder="Nome"
                      v-model="nome"
                    >
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input 
                      class="form-control 
                      valid" 
                      name="email" 
                      id="email"
                      type="email" onfocus="this.placeholder= ''"
                      onblur="this.placeholder= 'Email'"
                      placeholder="Email"
                      v-model="email"
                    >
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input 
                      class="form-control" 
                      name="fone" 
                      id="fone"
                      type="text" 
                      onfocus="this.placeholder= ''"
                      onblur="this.placeholder= 'Telefone'"
                      placeholder="Telefone"
                      v-model="telefone"
                    >
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input 
                      class="form-control" 
                      name="subject"      
                      id="subject"
                      type="text" onfocus="this.placeholder= ''"
                      onblur="this.placeholder= 'Assunto'"
                      placeholder="Assunto"
                      v-model="Contato.Assunto"
                    >
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <textarea 
                      class="form-control w-100" 
                      name="message"
                      id="message" cols="30" rows="9"
                      onfocus="this.placeholder= ''" onblur="this.placeholder='Mensagem'"
                      placeholder="Mensagem"
                      v-model="conteudo"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <button 
                  type="button" 
                  class="button button-contactForm boxed-btn"                 
                  @click="enviarDadosFormulario"
                >
                  Enviar Mensagem
                </button>
              </div>
            </form>
          </div>
            
          </div>
      </div>
    </section>


    <!-- Maps -->
    <!-- <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col px-0">
            <iframe 
              width="100%" 
              height="500" 
              :src="contatoMapa" 
              title="YouTube video player" 
              frameborder="0" 
              allowfullscreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </section> -->


    <!-- Parceiros -->
    <Parceiros />

  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'
import Parceiros from '../shared/Parceiros.vue'

export default {
  components: {
    TopoInternas,
    Parceiros
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      contato: [],
      contatoEmail: '',
      contatoEnd: '',
      contatoTel: '',
      contatoMapa: '',

      nome: '',
			email: '',
			telefone: '',
			conteudo: '',

      // Objeto Contato      
      Contato: {
        NomeEmpresa: "Ace | Acoplamentos & Redutores",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    // Blog Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    // Contato
    this.$http
    .get(this.$apiUrl + "/institucional/telas/Info.%20Contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI

      this.contatoEmail = this.contato[0].referencia
      this.contatoEnd = this.contato[0].descricao1
      this.contatoTel = this.contato[0].descricao2
      this.contatoMapa = this.contato[0].descricao3

    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail
      // this.Contato.Destinatario = "thiagokdma@gmail.com"

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Email: " + this.email +
        " <br /> Telefone: " + this.telefone +
        " <br /> Descrição: " + this.conteudo

      //validar vazios
      if(this.nome == '' || this.email == '' || this.telefone == '' || this.conteudo == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.nome = ''
            this.email = ''
            this.telefone = ''
            this.conteudo = ''
            this.Contato.Assunto=""
             this.$redirect_reload('/contatoenviado/');
        },error => {
          this.$mensagem_normal(error.bodyText)
          this.nome = ''
          this.email = ''
          this.telefone = ''
          this.conteudo = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
  
}

</script>



