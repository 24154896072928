<template>

  <div>

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />

    <!-- Sobre -->
    <div class="services-details spad-50">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-5 mb-5 mb-lg-0">
            <div class="section-tittle">
              <p v-html="sobreDescricao"></p>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <img 
              :src="sobreImg" 
              class="w-100 mb-30"
            >
              
          </div>
        </div>
      </div>
    </div>


    <!-- MVV -->
    <Tabs />

    <!-- Video -->
    <!-- <section class="video spad-100">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="section-tittle text-center mb-60">
              <h2 class="color-01">{{ videoTitulo }}</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10 text-center">
            <iframe 
              width="100%" 
              height="420" 
              :src="videoLink" 
              title="YouTube video player" 
              frameborder="0" 
              allowfullscreen
            >
            </iframe>
            
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <p 
              class="mt-5"
              v-html="videoDescricao"
            ></p>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Galeria -->
    <!-- <section 
      class="galeria spad-50"
      style="background-color: #eaf0f2;"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="section-tittle text-center mb-60">
              <h4>{{ galeriaTitulo }}</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div 
            class="col-6 col-md-4 col-lg-2 text-center px-0"
            v-for="imagem in galeriaSobre"
            :key="imagem.id"
          >
            <img :src="$apiUrlIndex + imagem.institucionalImg[0].urlImagem">
          </div>
        </div>
      </div>
    </section> -->

    <!-- Timeline -->
    <Timeline />


    <!-- Parceiros -->
    <Parceiros />

  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'
import Tabs from '../shared/Tabs.vue'
import Timeline from '../shared/Timeline.vue'
import Parceiros from '../shared/Parceiros.vue'

export default {
  components: {
    TopoInternas,
    Tabs,
    Timeline,
    Parceiros
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      sobre: [],
      sobreDescricao: '',
      sobreImg: '',

      video: [],
      videoTitulo: '',
      videoDescricao: '',
      videoVideo: '',

      galeriaSobre: [],
      galeriaTitulo: '',

    }
  },
  created() {

    // Sobre Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20sobre")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    // Sobre
    this.$http
    .get(this.$apiUrl + "/institucional/telas/descricao%20sobre")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobre = retornoAPI

      this.sobreDescricao = this.sobre[0].descricao1
      this.sobreImg = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem

    });


    // Vídeo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/video")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.video = retornoAPI

      this.videoTitulo = this.video[0].nome
      this.videoDescricao = this.video[0].descricao1
      this.videoLink = this.video[0].linkBtn1

    });


    // Galeria
    this.$http
    .get(this.$apiUrl + "/institucional/telas/fotos%20empresa")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.galeriaSobre = retornoAPI

      this.galeriaTitulo = this.galeriaSobre[0].nome
    });


  

  }
  
}

</script>

<style scoped>

  /* @import '/css/carousel.css'; */

</style>

